var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.clusterId)?_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"fixed-header":"","height":"100vh","headers":_vm.headers,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Gider Fatura Tahakkuk","icon":"mdi-progress-check","edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query},on:{"clear-filters":function($event){return _vm.clearFilters()},"click:edit":_vm.handleEditClick,"reload":_vm.loadList,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}},'rs-table-title-bar',_vm.titleBarAttrs,false),[_c('template',{slot:"buttons:prepend"},[_c('rs-select',{staticClass:"float-end me-2",staticStyle:{"max-width":"150px"},attrs:{"label":"Tarih Filtrele","items":_vm.dateFilterList,"dense":""},on:{"change":_vm.handleDateFilterChange},model:{value:(_vm.filterdate),callback:function ($$v) {_vm.filterdate=$$v},expression:"filterdate"}})],1)],2):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[(_vm.can('edit-invoice') && _vm.selectedItems.length)?_c('rs-action',{staticClass:"me-1 mb-1",on:{"click":function($event){return _vm.bulk()}}},[_vm._v(" Gider Türü Değiştir ")]):_vm._e()],1)],1)]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'expenses.invoices.edit',
            params: { id: item.invoice_id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"body.append",fn:function({ headers }){return [(_vm.list.length > 0)?_c('rs-table-foot-totals',{attrs:{"headers":headers,"totals":_vm.footTotals}}):_vm._e()]}},{key:"item.provider",fn:function({ item, value }){return [(item.provider_id)?_c('router-link',{attrs:{"title":"Tedarikçi Tanımına Git","to":{
            name: 'definitions.cluster-providers.edit',
            params: { id: item.provider_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}},{key:"item.remaining_amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.invoiced_type_id",fn:function({ item }){return [_vm._v(" "+_vm._s(item.invoiced_type)+" ")]}},{key:"item.amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.billed_on",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
            name: 'expenses.invoices.show',
            params: { id: item.invoice_id },
          }}},[_c('rs-table-cell-date',{attrs:{"value":value}})],1)]}},{key:"item.description",fn:function({ value }){return [_c('rs-table-cell-string',{attrs:{"limit":"20","value":value}})]}}],null,false,991691591),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir toplu yaşam alanı seçin. ")]),_c('InvoiceItemBulk',{ref:"bulk",attrs:{"ids":_vm.selectedItems},on:{"saved":_vm.handleFormSaved}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }